<template>
  <div class="pub-foot-box">
    <div class="info-box">
      <div class="inner">
        <div class="left">
          <div class="logo-box">
            <img src="../assets/foo-logo.png" alt="">
          </div>
          <div class="info">
            <h1>甘肃刘家峡景区</h1>
            <div class="det">
              <a>甘公网安备829930394904093号</a>
              <a>ICP备案号：陇ICP备20200030203号-1</a>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="code-box">
            <img src="../assets/code-gzh.png" alt="">
            <span>公众号</span>
          </div>
          <div class="code-box">
            <img src="../assets/code-wb.png" alt="">
            <span>微博</span>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-box">Copyright@2022.All rights reserved.</div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
  .pub-foot-box{
    .info-box{
      height: 272px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #EFEFEF;
      .inner{
        width: 1054px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
          display: flex;
          .logo-box{
            width: 131px;
            height: 115px;
            margin-right: 20px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .info{
            height: 115px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            h1{
              line-height: 42px;
              font-size: 32px;
              font-weight: bold;
              color: #000000;
              text-align: left;
            }
            .det{
              display: flex;
              flex-direction: column;
              a{
                line-height: 19px;
                text-align: left;
                font-size: 14px;
                color: #000000;
                cursor: pointer;
                margin-top: 10px;
              }
            }
          }
        }
        .right{
          display: flex;
          align-items: center;
          .code-box{
            display: flex;
            flex-direction: column;
            margin-left: 25px;
            img{
              width: 120px;
              height: 120px;
              margin-bottom: 13px;
            }
            span{
              line-height: 21px;
              font-size: 16px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              color: #000000;
            }
          }
        }
      }
    }
    .copy-box{
      height: 52px;
      line-height: 52px;
      text-align: center;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      background: #D4D4D4;
    }
  }
  
  @media screen and (max-width: 780px) {
    .pub-foot-box{
      .info-box{
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #EFEFEF;
        .inner{
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 1rem;
          .left{
            display: flex;
            .logo-box{
              display: none;
            }
            .info{
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              h1{
                line-height: 1.8;
                font-size: 0.8rem;
                color: #000000;
              }
              .det{
                display: flex;
                flex-direction: column;
                a{
                  line-height: 1.6;
                  font-size: 0.6rem;
                  color: #000000;
                  margin-top: 0.4rem;
                }
              }
            }
          }
          .right{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 1rem;
            .code-box{
              display: flex;
              flex-direction: column;
              margin-left: 25px;
              img{
                width: 5rem;
                height: 5rem;
                margin-bottom: 0.5rem;
              }
              span{
                line-height: 1.6;
                font-size: 0.7rem;
                color: #000000;
              }
            }
          }
        }
      }
      .copy-box{
        height: 2rem;
        line-height: 2rem;
        font-size: 0.7rem;
      }
    }
  }
</style>