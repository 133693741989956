<template>
  <div class="header">
    <div class="w1200 header-cont">
      <div class="logo">
        <img src="../assets/logo.png" alt="">
      </div>
      <div :class="appMenuShow?'menu-box app-show':'menu-box'">
        <a :class="activeMenu=='/'?'active':''" @click="toUrl('/')">首页</a>
        <a :class="activeMenu=='/scenicIntro'?'active':''" @click="toUrl('/scenicIntro')">景点介绍</a>
        <a :class="activeMenu=='/companyIntro'?'active':''" @click="toUrl('/companyIntro')">企业简介</a>
        <a :class="activeMenu=='/about'?'active':''" @click="toUrl('/about')">联系我们</a>
      </div>
    </div>
    <!-- 手机端菜单展开按钮 -->
    <div class="more-menu-btn">
      <img src="../assets/more_menu.png" alt="" @click="showMenu">
    </div>
    <!-- 菜单下拉遮罩 -->
    <div v-if="appMenuShow" class="menu-mask" @click="hideMenu"></div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      activeMenu: '/',
      appMenuShow: false, // app端菜单是否显示
    };
  },
  mounted() {
    
  },
  /* eslint-disable */
  watch: {
    '$route.path': function(newVal,oldVal){
      this.activeMenu = newVal
    }
  },
  methods: {
    toUrl(url) {
      this.$router.push({path: url})
      this.activeMenu = url
      this.appMenuShow = false
    },
    // 展示菜单
    showMenu() {
      this.appMenuShow = !this.appMenuShow
    },
    // 隐藏菜单
    hideMenu() {
      this.appMenuShow = false
    }
  }
}
</script>

<style scoped lang="scss">
  .header{
    width: 100%;
    height: 100px;
    background: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    .more-menu-btn{
      display: none;
    }
    .header-cont{
      height: 100px;
      display: flex;
      align-items: center;
      position: relative;
      .logo{
        width: 82px;
      }
      .menu-box{
        display: flex;
        align-items: center;
        margin-left: 140px;
        a{
          display: inline-block;
          // width: 135px;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          line-height: 100px;
          padding: 0 30px;
          margin-right: 20px;
          cursor: pointer;
          user-select: none;
          position: relative;
          &.active{
            font-size: 18px;
            font-weight: bold;
            &::after{
              content: "";
              width: 100%;
              height: 10px;
              background: #786459;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 780px) {
    .header{
      height: 2rem;
      .menu-mask{
        width: 100%;
        height: calc(100% - 2rem);
        position: fixed;
        top: 2rem;
        left: 0;
        z-index: -2;
        background-color: rgba(0, 0, 0, .3);
      }
      .header-cont{
          width: 100%;
          height: 100%;
          justify-content: center;
          background-color: #FFFFFF;
          position: relative;
          .logo{
            width: 1.4rem;
            img{
              width: 100%;
              height: auto;
            }
          }
          .menu-box{
            width: 6.5rem;
            margin-left: 0;
            flex-direction: column;
            position: absolute;
            top: -176px;
            right: 0;
            z-index: -1;
            background: #FFFFFF;
            border-radius: 0px 0px 4px 4px;
            transition: all .5s;
            &.app-show{
              top: 2rem;
            }
            a{
              display: inline-block;
              width: 100%;
              height: 2.2rem;
              line-height: 2.2rem;
              padding: 0;
              margin: 0;
              text-align: center;
              font-size: 12px;
              color: #000000;
              position: relative;
              &.active{
                font-size: 12px;
                font-weight: 100;
                background: #E2E2E2;
                &::after{
                  display: none;
                }
              }
            }
        }
      }
       
      .more-menu-btn{
        display: block;
        position: absolute;
        top: 50%;
        right: 1.1rem;
        transform: translateY(-50%);
        z-index: 100;
        img{
          width: 0.65rem;
          height: 0.65rem;
        }
      }
    }
  }
</style>