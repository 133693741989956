<template>
  <div class="home">
    <div class="banner-box">
      <swiper ref="mySwiper" :options="swiperOption">
        <swiper-slide>
          <div class="img-box">
            <img src="../assets/banner1.jpg" alt="">
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="img-box">
            <img src="../assets/banner2.jpg" alt="">
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="img-box">
            <img src="../assets/banner3.jpg" alt="">
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="img-box">
            <img src="../assets/banner4.jpg" alt="">
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="button-prev"></div>
      </swiper>
    </div>
    <!-- 景区景点 -->
    <div class="pub-title">
      <span class="text">景区景点</span>
      <span class="eng-text">SCENIC SPOTS</span>
    </div>
    <div class="scenic-box">
      <swiper ref="scenicSwiper" :options="scenicOption">
        <swiper-slide>
          <img src="../assets/attract01.png" alt="">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/attract02.png" alt="">
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/attract03.png" alt="">
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </div>
    <!-- 景点简介 -->
    <div class="attrac-intro">
      <div class="w1200 inner">
        <div class="left">
          <div class="title-box">
            <span class="text">景点简介</span>
            <span class="eng-text">INTRODUCTION TO SCENIC SPOTS</span>
          </div>
          <div class="intro-box" v-if="introIndex == 0">
            <h3>刘家峡恐龙国家地质公园</h3>
            <p>
              刘家峡恐龙国家地质公园位于甘肃省永靖县太极湖北岸，东距省会兰州40公里，东南距县城10公里，交通极为便利。
              公园内刘家峡恐龙足印群，保存十分完整和清晰，立体感强。同时，在同一岩层层面上还保存有恐龙卧迹、尾部拖痕及粪迹等，构成了足印、卧迹、拖痕和粪迹共存的场面，非常独特。在已揭露的总计2000平方米的岩层层面上至少有两类巨型晰脚类，两类兽脚类，一类似鸟龙类，一类翼龙类和其它三类形态独特、尚未归属的足印，代表至少有9个属种。在同一地点出现如此多样的食植类和食肉类恐龙足印，在国内尚属首次，在世界上也极为罕见。其中最大的一组蜥脚类足印后足印长150厘米，宽120厘米，前足印长70厘米，宽110厘米，步幅375厘米，左右足印外侧缘间距345厘米，为世界之最，专家们确定，留下此足印的恐龙，两步就可以跨过一个篮球场，体长至少有20米，体重接近50吨，而且该类足印前足小，后足大，前后足足印成对出现并有规律地部分叠覆，为国内外首次发现。总之，该恐龙足印化石规模之大、种类之多、保存之完好、清晰度之高、立体感之强，均为世界少有。
            </p>
            <div class="detail-btn" @click="showIntroDetail">查看详情</div>
          </div>
          <div class="intro-box" v-if="introIndex == 1">
            <h3>太极岛</h3>
            <p>
              黄河出刘家峡便转弯向西而去，又在盐锅峡大坝前形成了一个人造湖泊，因状如太极，故名太极湖。太极湖中有大小岛屿9个，面积4000亩，周边有百年枣林1万多亩，鱼塘2000亩，荷花500亩，是西北地区典型的黄河湿地自然景观，岛上碧水如镜，苇花如织，荷叶田田，枣林蓊郁，稻菽起浪，银鲤跃波，红鸥翔集，构成了一幅美不胜收的画卷，展现了陇上江南的无穷魅力，是垂钓赏荷、生态观光、休闲度假的理想之地。
            </p>
            <div class="detail-btn" @click="showIntroDetail">查看详情</div>
          </div>
          <div class="intro-box" v-if="introIndex == 2">
            <h3>黄河水电博览园</h3>
            <p>
              黄河水电博览园位于刘家峡大坝码头片区,这里是黄河三峡旅游区的重要门户，也是进出永靖的标志性门户。总占地面积360亩，总建筑面积2.6万平方米，停车位800多个，项目总投资5.7亿元，博览园分入口商业服务区、治水名人园区、港航配套服务区、治水文化展示区四大功能区。建有游客服务中心、迎宾大道、生态停车场、山海经雕塑广场、黄河水电博览馆、报恩寺等。黄河水电博览园是全国唯一的治水文化主题景区、全国唯一的水电博览中心，是黄河三峡的地标性建筑和旅游集散中心，是一处集中展示大禹文化、黄河文化的承载地，极大提升了黄河三峡旅游的品牌和形象。  
            </p>
            <div class="detail-btn" @click="showIntroDetail">查看详情</div>
          </div>
        </div>
        <div class="right">
          <swiper ref="introSwiper" :options="introOption">
            <swiper-slide>
              <div class="img-box">
                <img src="../assets/intro-banner1.png" alt="">
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="img-box">
                <img src="../assets/intro-banner2.png" alt="">
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="img-box">
                <img src="../assets/intro-banner3.png" alt="">
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <!-- 公司简介 -->
    <div class="pub-title">
      <span class="text">公司简介</span>
      <span class="eng-text">COMPANY PROFILE</span>
    </div>
    <div class="w1200 comp-text-intro">
      我公司于2015年10月底正式挂牌运营。注册资金4.08亿元，为县属国有企业，主要负责永靖县范围内所有旅游资源的投资、招商、规划、开发、整合、建设、经营、管理和包装上市；旅游船舶运营及出租车运营。公司承担着项目建设、经营管理、旅游商品开发、配套设施建设、投融资五大职能。公司现有职工86人（包括公职人员4人）。其中总经理1人；副总经理2名；综合办公室2人；财务2人；出租车2人；保洁1人；太极岛景区37人（其中：售票员6人、观光车司机10人、码头派船1人、保洁12、保安2人、值夜班1人、门岗1人、厨师1人）；博览馆8人（其中：讲解员4人、保洁2人、值班1人、职工1人）；夜游码头4人（其中：安全员2人；驾驶员2人）；黄河三峡旅行社8人，滑翔营地8人（其中：职工3人、售票员1人、保安2人、保洁2人）。恐龙博物馆12人（其中：讲解员4人、水暖工1人、电工1人、保安2人、保洁4人）公司下设永靖县西部水乡旅游有限责任公司、永靖县黄河三峡旅游汽车出租有限公司、甘肃黄河三峡旅行社有限公司、甘肃黄河三峡实业有限公司和刘家峡国际滑翔营地。
    </div>
    <div class="w1200 comp-img-intro">
      <div class="img-box"><img src="../assets/company01.png" alt=""></div>
      <div class="img-box"><img src="../assets/company02.png" alt=""></div>
      <div class="img-box"><img src="../assets/company03.png" alt=""></div>
    </div>
    <div class="comp-btn-box">
      <div class="det-btn" @click="showCompDetail">查看详情</div>
    </div>
    <!-- 联系我们 -->
    <div class="contact-cont">
      <div class="inner">
        <div class="title-box">
          <h3>联系我们</h3>
          <h4>CONTACT US</h4>
        </div>
        <div class="info-box">
          <div class="left">
            <div class="item">
              <img src="../assets/icon_phone.png" alt="">
              <span>服务热线：18088888888</span>
            </div>
            <div class="item">
              <img src="../assets/icon_email.png" alt="">
              <span>公司邮箱：88888888@163.com</span>
            </div>
            <div class="item">
              <img src="../assets/icon_address.png" alt="">
              <span>公司地址： 甘肃省临夏州永靖县刘家峡镇</span>
            </div>
          </div>
          <div class="line"></div>
          <div class="right">
            <baidu-map 
              :style="{width: '100%',height: '100%'}" 
              class="map"
              ak="I7ioGmQbypWKuWMNv5fLPKU4mt3gzhrz" 
              :zoom="14"
              @ready="handler"
              :center="{lng: 103.32798, lat: 35.948668}"
              :scroll-wheel-zoom="true">
            </baidu-map>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'

export default {
  name: "Home",
  components: {
    BaiduMap,
  },
  data() {
    const that = this
    return {
      swiperOption: {
        speed: 1000,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: '.swiper-pagination',
        },
      },
      scenicOption: {
        slidesPerView: 3,
        spaceBetween: 15,
        autoplay: false,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      introIndex: 0, // 景点介绍显示第几个
      introOption: {
        watchSlidesProgress: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        loop: true,
        autoplay: {
          delay: 4000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loopedSlides: 5,
        on: {
          /* eslint-disable */
          progress: function(progress) {
            for (let i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i);
              var slideProgress = this.slides[i].progress;
              let modify = 1;
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
              }
              let translate = slideProgress * modify * 260 + 'px';
              let scale = 1 - Math.abs(slideProgress) / 5;
              let zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
              slide.transform('translateX(' + translate + ') scale(' + scale + ')');
              slide.css('zIndex', zIndex);
              slide.css('opacity', 1);
              if (Math.abs(slideProgress) > 3) {
                slide.css('opacity', 0);
              }
            }
          },
          setTransition: function(transition) {
            for (var i = 0; i < this.slides.length; i++) {
              var slide = this.slides.eq(i)
              slide.transition(transition);
            }
          },
          slideChangeTransitionEnd: function () {
            that.introIndex = this.realIndex
          }
        }

      }
    }
  },
  methods: {
    handler({ BMap, map }) {
      const point = new BMap.Point(103.32798, 35.948668)
      const marker = new BMap.Marker(point)
      map.addOverlay(marker)
    },
    // 查看景点介绍详情
    showIntroDetail() {
      this.$router.push({path:'/scenicIntro'})
    },
    // 查看公司详情
    showCompDetail() {
      this.$router.push({path:'/companyIntro'})
    }
  }
};
</script>

<style scoped lang="scss">
.banner-box{
  .swiper-container{
    width: 100%;
    height: calc(100vh - 100px);
    /deep/.swiper-pagination-bullet{
      width: 21px;
      height: 4px;
      background: #FFFFFF;
      border-radius: 0px 0px 0px 0px;
      opacity: 0.61;
      &.swiper-pagination-bullet-active{
        opacity: 1;
      }
    }
  }
  .swiper-slide {
    text-align: center;
    width: 100%;
    height: 100%;
    .img-box{
      width: 100%;
      height: 100%;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
}
.pub-title{
  padding: 35px 0 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .text{
    font-size: 20px;
    line-height: 26px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    margin-bottom: 10px;
  }
  .eng-text{
    line-height: 21px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }
}
.scenic-box{
  height: 230px;
  position: relative;
  .swiper-container{
    width: 1200px;
    margin: 0 auto;
    height: 100%;
    .swiper-slide{
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.36);
    }
    .swiper-slide,.swiper-slide img{
      width: 100%;
      height: 100%;
    }
  }
  .swiper-button-prev, .swiper-button-next{
    width: 34px;
    height: 34px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &::after{
      display: none;
    }
  }
  .swiper-button-prev{
    background-image: url(../assets/banner-left.png);
    left: calc(50% - 650px);
  }
  .swiper-button-next{
    background-image: url(../assets/banner-right.png);
    right: calc(50% - 650px);
  }
}
.attrac-intro{
  width: 100%;
  height: 438px;
  margin-top: 30px;
  background-image: url(../assets/attrac-banner.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .inner{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
      width: 560px;
      height: 100%;
      .title-box{
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 33px 0 0 0;
        .text{
          line-height: 26px;
          font-size: 20px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
        }
        .eng-text{
          margin-top: 10px;
          line-height: 21px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          color: #FFFFFF;
        }
      }
      .intro-box{
        margin-top: 40px;
        text-align: left;
        h3{
          line-height: 19px;
          font-size: 14px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
        }
        p{
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          color: #FFFFFF;
          line-height: 30px;
          margin-top: 10px;
          overflow:hidden; 
          text-overflow:ellipsis;
          display:-webkit-box; 
          -webkit-box-orient:vertical;
          -webkit-line-clamp: 5;
        }
        .detail-btn{
          width: 148px;
          height: 51px;
          line-height: 51px;
          text-align: center;
          font-size: 14px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
          background: #786459;
          border: 5px solid #fff;
          cursor: pointer;
          margin-top: 30px;
        }
      }
    }
    .right{
      width: 600px;
      height: 340px;
      .swiper-container{
        width: 100%;
        height: 340px;
      }
      .swiper-slide {
        width: 420px;
	      height: 308px;
        .img-box{
          width: 100%;
          height: 100%;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.comp-text-intro{
  font-size: 14px;
  line-height: 30px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  color: #000000;
  text-align: left;
}
.comp-img-intro{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  .img-box{
    width: 389px;
    height: 227px;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.36);
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.comp-btn-box{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  .det-btn{
    width: 148px;
    height: 51px;
    line-height: 51px;
    text-align: center;
    font-size: 14px;
    font-family: Microsoft YaHei-Bold, Microsoft YaHei;
    font-weight: bold;
    color: #FFFFFF;
    background: #786459;
    border: 5px solid #DCDCDC;
    cursor: pointer;
  }
}
.contact-cont{
  margin-top: 37px;
  padding-bottom: 13px;
  background: linear-gradient(to bottom, #fff 0%,#fff 46%,#EFEFEF 47%,#EFEFEF 100%);
  .inner{
    width: 1054px;
    height: 337px;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 6px 8px 1px rgba(0, 0, 0, 0.1607843137254902);
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 10px solid #F6F6F6;
    .title-box{
      text-align: left;
      padding: 28px 0 0 70px;
      h3{
        line-height: 26px;
        font-size: 20px;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        margin-bottom: 10px;
      }
      h4{
        line-height: 21px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        color: #000000;
      }
    }
    .info-box{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 50px 0;
      .left{
        .item{
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          img{
            width: 21px;
            height: 21px;
            margin-right: 20px;
          }
          span{
            line-height: 21px;
            font-size: 16px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #000000;
          }
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .line{
        width: 1px;
        height: 113px;
        background: #B1B1B1;
      }
      .right{
        width: 353px;
        height: 190px;
        background: #EFEFEF;
      }
    }
  }
}


@media screen and (max-width: 780px) {
  .banner-box{
    .swiper-container{
      width: 100%;
      height: 7.8rem;
      /deep/.swiper-pagination-bullet{
        display: none;
      }
    }
  }
  .pub-title{
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text{
      font-size: 0.8rem;
      line-height: 1.2;
      margin-bottom: 0.5rem;
    }
    .eng-text{
      line-height: 1.2;
      font-size: 0.6rem;
    }
  }
  .scenic-box{
    height: 3rem;
    .swiper-container{
      width: calc(100% - 4.4rem);
      margin: 0 auto;
      height: 100%;
    }
    .swiper-button-prev, .swiper-button-next{
      width: 1rem;
      height: 1rem;
    }
    .swiper-button-prev{
      background-image: url(../assets/banner-left.png);
      left: 10px;
      top: 70%;
    }
    .swiper-button-next{
      background-image: url(../assets/banner-right.png);
      right: 10px;
      top: 70%;
    }
  }
  .attrac-intro{
    box-sizing: border-box;
    height: auto;
    margin-top: 0;
    background-image: none;
    padding: 0 1rem;
    .inner{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .left{
        width: 100%;
        .title-box{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 0.5rem 0 0 0;
          .text{
            line-height: 1.2;
            font-size: 0.8rem;
            color: #000000;
            text-align: center;
          }
          .eng-text{
            margin-top: 0.5rem;
            line-height: 1.2;
            font-size: 0.6rem;
            color: #000000;
            text-align: center;
          }
        }
        .intro-box{
          margin-top: 1rem;
          text-align: left;
          h3{
            line-height: 1.3;
            font-size: 0.7rem;
            color: #000000;
            text-align: center;
          }
          p{
            font-size: 0.7rem;
            color: #000000;
            line-height: 1.6;
            margin-top: 0.5rem;
            overflow:hidden; 
            text-overflow:ellipsis;
            display:-webkit-box; 
            -webkit-box-orient:vertical;
            -webkit-line-clamp: 5;
          }
          .detail-btn{
            display: none;
          }
        }
      }
      .right{
        width: 100%;
        height: auto;
        margin-top: 1rem;
        .swiper-container{
          width: 100%;
          height: auto;
        }
        .swiper-slide {
          width: 50%;
          height: 4rem;
          .img-box{
            width: 100%;
            height: 100%;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .comp-text-intro{
    box-sizing: border-box;
    padding: 0 1rem;
    font-size: 0.7rem;
    line-height: 1.6;
    color: #000000;
    text-align: left;
  }
  .comp-img-intro{
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 1rem;
    .img-box{
      width: 31%;
      height: 3.13rem;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.36);
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .comp-btn-box{
    display: none;
  }
  .contact-cont{
    margin-top: 1.5rem;
    padding-bottom: 0.5rem;
    background: none;
    .inner{
      width: 100%;
      height: auto;
      margin: 0 auto;
      background: #FFFFFF;
      box-shadow: none;
      border: none;
      .title-box{
        text-align: center;
        padding: 0;
        h3{
          line-height: 1.2;
          font-size: 0.8rem;
          color: #000000;
          margin-bottom: 0.5rem;
        }
        h4{
          line-height: 1.2;
          font-size: 0.6rem;
          color: #000000;
        }
      }
      .info-box{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 0.5rem 1rem 0;
        .left{
          .item{
            display: flex;
            align-items: center;
            margin-bottom: 0.5rem;
            img{
              width: 1rem;
              height: 1rem;
              margin-right: 0.85rem;
            }
            span{
              line-height: 1.6;
              font-size: 0.8rem;
              color: #000000;
            }
            &:last-child{
              margin-bottom: 0;
            }
          }
        }
        .line{
          display: none;
        }
        .right{
          width: 100%;
          height: 9rem;
          margin-top: 1rem;
          background: #EFEFEF;
        }
      }
    }
  }


}
</style>
