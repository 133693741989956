<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "",
  components: {
    Header,
    Footer
  },
  created() {
    
  }
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100%;
}
html {
  width: 100%;
  -webkit-text-size-adjust: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
  font-size: 20px;
}
body {
  width: 100%;
  height: 100%;
  font-size: 20px;
  color: #333;
  line-height: 1.6;
  background: #fff;
  min-height: 100%;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
}
* {
  margin: 0;
  padding: 0;
  border: 0;
}
ul,
ul li,
ol,
li {
  list-style: none outside none;
}
img {
  vertical-align: middle;
  border: none;
}
i {
  font: inherit;
}
input,
select,
option {
  vertical-align: middle;
  border-radius: 0px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}
input[type="text"],
input[type="button"],
input[type="submit"],
input[type="reset"] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
  outline: none;
}
.fl {
  float: left;
  display: inline;
}
.fr {
  float: right;
  display: inline;
}
h1,
h2,
h3,
h4,
h5,
h6,
i,
strong {
  font-weight: normal;
}
select::-ms-expand {
  display: none;
}
a {
  color: #fff;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:hover {
  text-decoration: none;
  outline: none;
}
body,
ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
table,
td,
img,
div,
dl,
dt,
dd,
input {
  margin: 0;
  padding: 0;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.w1200{
  width: 1200px;
  margin: 0 auto;
}
.home{
  margin-top: 100px;
}
@media screen and (max-width: 780px) {
  .w1200{
    width: 100%;
    margin: 0 auto;
  }
  .home{
    margin-top: 2rem;
  }
}
</style>
